import React from "react";
import theme from "../../../../util/theme";
import styled from "styled-components";
import { TestimonyCarousel } from "./TestimonyCarousel";
import { Awards } from "../../index/Awards";
import { mobileSize } from "../../../../util/variables";

const TestimonySegment = () => {
  return (
    <Container>
      <Awards />
      <Spacer />
      <TestimonyCarousel />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 644px; */
  width: 100%;
  padding: 80px 52px;

  background: ${theme.PRIMARY_COLOR};
  @media ${mobileSize} {
    height: auto;
    padding: 40px 32px;
  }
`;

const Spacer = styled.div`
  margin-bottom: 80px;
  @media ${mobileSize} {
    margin-bottom: 40px;
  }
`;

export { TestimonySegment };
