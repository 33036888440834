import React, { useState } from "react";
import styled from "styled-components";
import theme from "../../../../util/theme";
import { laptopSize, mobileSize } from "../../../../util/variables";
import LeftQuoteSrc from "../../../../content/img/homepage/left-quote.svg";
import RightQuoteSrc from "../../../../content/img/homepage/right-quote.svg";
import { AppText, FlexDiv } from "../../../ui";
import StarsSrc from "../../../../content/assets/Stars.svg";

const TESTIMONY_SLIDES = [
  {
    circleColor: "rgba(197, 200, 0, 0.75)",
    text:
      "Imprint is <b>completely life changing.</b> As a person that always loves reading but doesn’t have much time, this app is <b>perfect for me.</b>",
  },
  {
    circleColor: "rgba(255, 240, 63, 0.75)",
    text:
      "This app is a <b>must-have</b> for the avid reader and learner… The visuals really do wonders!",
  },
  {
    circleColor: "rgba(255, 175, 204, 0.75)",
    text:
      "Imprint helps me <b>stay engaged.</b> I retain more information than I would if I read on my own. I would recommend it to anyone trying to <b>pick up better habits and learn more.</b>",
  },
  {
    circleColor: "rgba(255, 48, 0, 0.75)",
    text:
      "The time I spent doom scrolling has been replaced with <b>absorbing information</b> that’s <b>actually useful</b> to me. I always feel better leaving the app than when I went in.",
  },
];

export const TestimonyCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide(current => (current === TESTIMONY_SLIDES.length - 1 ? 0 : current + 1));
  };

  const prevSlide = () => {
    setCurrentSlide(current => (current === 0 ? TESTIMONY_SLIDES.length - 1 : current - 1));
  };

  return (
    <CarouselContainer>
      <LeftArrow aria-label="previous slide" onClick={prevSlide} />

      <SlideWindow>
        <LeftQuote>
          <img src={LeftQuoteSrc} alt='"' width={60} height={64} />
          <Circle color={TESTIMONY_SLIDES[currentSlide].circleColor} />
        </LeftQuote>
        <SlideTrack currentSlide={currentSlide}>
          {TESTIMONY_SLIDES.map(slide => (
            <CarouselSlide key={slide.text} text={slide.text} />
          ))}
        </SlideTrack>
        <RightQuote>
          <img src={RightQuoteSrc} alt='"' width={60} height={64} />
          <Circle color={TESTIMONY_SLIDES[currentSlide].circleColor} />
        </RightQuote>
      </SlideWindow>

      <RightArrow aria-label="next slide" onClick={nextSlide} />
    </CarouselContainer>
  );
};

interface CarouselSlideProps {
  text: string;
}

export const CarouselSlide: React.FC<CarouselSlideProps> = ({ text }) => {
  return (
    <Slide direction="column" justify="center" align="center">
      <AppText
        noMargin
        fontSize={32}
        mobileFontSize={18}
        dangerouslySetInnerHTML={{ __html: text }}
        style={{ maxWidth: 642 }}
      />
      <Stars
        src={StarsSrc}
        alt="5 stars"
        width={144}
        height={27}
        style={{ filter: "brightness(0)", marginTop: 24 }}
      />
    </Slide>
  );
};

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const SlideWindow = styled.div`
  position: relative;
  overflow: hidden;
  background: ${theme.WHITE_COLOR};
  border-radius: 16px;
`;

export const SlideTrack = styled.div<{ currentSlide: number }>`
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
  transform: translateX(-${props => props.currentSlide * 100}%);
`;

const Slide = styled(FlexDiv as any)`
  padding: 64px 142px;
  flex-shrink: 0;
  width: 100%;
  text-align: center;
  
  @media ${mobileSize} {
    padding: 80px 16px;
  }
`;

const ArrowButton = styled.button<{ color?: string }>`
  border-top: 6px solid ${props => props.color ?? theme.WHITE_COLOR};
  border-right: 6px solid ${props => props.color ?? theme.WHITE_COLOR};
  padding: 12px;
  background-color: transparent;
`;

export const LeftArrow = styled(ArrowButton)`
  transform: rotate(-135deg);
`;

export const RightArrow = styled(ArrowButton)`
  transform: rotate(45deg);
`;

const LeftQuote = styled.div`
  position: absolute;
  left: 64px;
  top: 48px;

  img {
    position: absolute;
    display: block;
    z-index: 1;
  }

  div {
    position: relative;
    left: 20px;
    bottom: -24px;
    z-index: 0;
  }

  @media ${mobileSize} {
    left: 24px;
    top: 0px;
    div {
      left: 12px;
    }
  }
`;

const RightQuote = styled.div`
  position: absolute;
  right: 64px;
  bottom: 48px;

  img {
    position: absolute;
    display: block;
    z-index: 1;
  }

  div {
    position: relative;
    right: 20px;
    top: 24px;
    z-index: 0;
  }
  @media ${mobileSize} {
    right: 24px;
    bottom: 40px;
    div {
      right: 12px;
    }
  }
`;

const Circle = styled.div<{ color: string }>`
  height: 64px;
  width: 64px;
  border-radius: 50%;
  background-color: ${props => props.color};
  transition: background-color 0.5s ease-in-out;
  z-index: 0;

  @media ${laptopSize} {
    width: 48px;
    height: 48px;
  }

  @media ${mobileSize} {
    width: 28px;
    height: 28px;
  }
`;

const Stars = styled.img`
  @media ${mobileSize} {
    width: 90px;
    height: 17px;
  }
`;
