import React from "react";
import { FlexDiv } from "../../ui";
import theme from "../../../util/theme";
import LeftLaurel from "../../../content/img/homepage/left-laurel.svg";
import AppleIcon from "../../../content/assets/icons/apple-logo.svg";
import GooglePlayIcon from "../../../content/assets/icons/google-play-icon-white.svg";
import styled, { css } from "styled-components";
import { mobileSize } from "../../../util/variables";

enum PLATFORM {
  Apple = "Apple",
  Google = "Google Play",
}

const AWARDS_DATA = [
  {
    type: PLATFORM.Google,
    text: "APP OF THE YEAR 2023",
  },
  {
    type: PLATFORM.Apple,
    text: "EDITORS’ CHOICE & APP OF THE DAY",
  },
  {
    type: PLATFORM.Apple,
    text: "ESSENTIAL EDUCATION APP",
  },
];

interface AwardProps {
  type: PLATFORM;
  text: string;
  dark?: boolean;
  darkLaurel?: boolean;
}

interface AwardsProps {
  dark?: boolean;
  darkLaurel?: boolean;
}

export const Awards = ({ dark, darkLaurel }: AwardsProps) => {
  return (
    <AwardsContainer>
      {AWARDS_DATA.map(award => (
        <Award key={award.text} dark={dark} darkLaurel={darkLaurel} {...award} />
      ))}
    </AwardsContainer>
  );
};

const Award: React.FC<AwardProps> = ({ type, text, dark, darkLaurel }) => {
  return (
    <FlexDiv align="center">
      <Laurel
        src={LeftLaurel}
        dark={darkLaurel}
        alt="left laurel"
        height={88}
        width={36.5}
      />
      <FlexDiv direction="column" align="center">
        <FlexDiv justify="center" align="center" mobileAlign="flex-end" gap={8} mobileGap={4}>
          <PlatformImg
            src={type === PLATFORM.Apple ? AppleIcon : GooglePlayIcon}
            width={16}
            height={19}
            alt={text}
            dark={dark}
          />
          <PlatformText dark={dark}>
            {type === PLATFORM.Apple ? PLATFORM.Apple : PLATFORM.Google}
          </PlatformText>
        </FlexDiv>
        <AwardText dark={dark}>{text}</AwardText>
      </FlexDiv>
      <Laurel
        src={LeftLaurel}
        dark={darkLaurel}
        alt="right laurel"
        height={88}
        width={36.5}
        style={{ transform: "scaleX(-1)" }}
      />
    </FlexDiv>
  );
};

const AwardsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;

  @media ${mobileSize} {
    gap: 16px;
  }
`;

const PlatformText = styled.p<{ dark?: boolean }>`
  margin: 0;
  font-size: 18px;
  color: ${props => (props.dark ? theme.BLACK_COLOR : theme.WHITE_COLOR)};

  @media ${mobileSize} {
    font-size: 10px;
    line-height: 14px;
  }
`;

const PlatformImg = styled.img<{ dark?: boolean }>`
  ${props =>
    props.dark &&
    css`
      filter: brightness(0);
    `}
  @media ${mobileSize} {
    width: 9px;
    height: 13px;
  }
  pointer-events: none;
`;

const AwardText = styled.p<{ dark?: boolean }>`
  max-width: 215px;
  margin: 0;
  margin-top: 4px;

  font-size: 24px;
  font-weight: 500;
  font-family: ${theme.SECONDARY_FONT};
  text-transform: uppercase;
  text-align: center;
  color: ${props => (props.dark ? theme.BLACK_COLOR : theme.WHITE_COLOR)};
  @media ${mobileSize} {
    font-size: 13px;
    max-width: 110px;
    margin-top: 4px;
    line-height: 12px;
    margin: 0 4px 0 4px;
  }
`;

const Laurel = styled.img<{ dark?: boolean }>`
  filter: ${props => (props.dark ? "brightness(0)" : "none")};
  pointer-events: none;
  @media ${mobileSize} {
    width: 20px;
    height: 48px;
  }
`;
